@import 'mastodon/mixins';
@import 'mastodon/variables';
@import 'fonts/roboto';
@import 'fonts/roboto-mono';
@import 'fonts/montserrat';

@import 'mastodon/reset';
@import 'mastodon/basics';
@import 'mastodon/containers';
@import 'mastodon/lists';
@import 'mastodon/footer';
@import 'mastodon/compact_header';
@import 'mastodon/widgets';
@import 'mastodon/forms';
@import 'mastodon/accounts';
@import 'mastodon/statuses';
@import 'mastodon/boost';
@import 'mastodon/components';
@import 'mastodon/polls';
@import 'mastodon/introduction';
@import 'mastodon/modal';
@import 'mastodon/emoji_picker';
@import 'mastodon/about';
@import 'mastodon/tables';
@import 'mastodon/admin';
@import 'mastodon/dashboard';
@import 'mastodon/rtl';
@import 'mastodon/accessibility';

.column {
  flex-grow: 1;
}
